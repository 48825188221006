import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Container, Box, Typography } from '@mui/material';
import axios from 'axios';
import config from '../config';

const FileCheckForm = ({ file, onSave, onCancel, auth }) => {
  const [filePath, setFileCheckPath] = useState(file ? file.filePath : '');
  const [name, setName] = useState(file ? file.name : '');
  const [isEnabled, setIsEnabled] = useState(file ? file.isEnabled : true);
  const [fileChecks, setFileChecks] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFileChecks = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/fileChecks`, {
          auth: auth,
        });
        setFileChecks(response.data.list);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };
    fetchFileChecks();
  }, [auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = { filePath, name, isEnabled };
      if (file) {
        await axios.put(`${config.API_BASE_URL}/fileCheck/${file.id}`, data, {
          auth: auth,
        });
      } else {
        await axios.post(`${config.API_BASE_URL}/fileCheck`, data, {
          auth: auth,
        });
      }
      onSave();
    } catch (error) {
      const errorMessage = `HTTP Status: ${error.response.status}, Error Code: ${error.response.data.errorCode}`;
      setError(errorMessage);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          maxWidth: '500px',
          margin: '20px auto 0',
          padding: '20px',
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h5" component="h2" gutterBottom>
          {file ? 'Edit File Check' : 'New File Check'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            margin="normal"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            margin="normal"
            label="File Path"
            value={filePath}
            onChange={(e) => setFileCheckPath(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isEnabled}
                onChange={(e) => setIsEnabled(e.target.checked)}
              />
            }
            label="Is Enabled"
          />
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Box display="flex" alignItems="center" gap="20px">
            <Button type="submit" variant="contained" color="primary">
              Save
            </Button>
            <Button variant="contained" onClick={onCancel}>
              Cancel
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default FileCheckForm;

